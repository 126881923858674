.text-center {
    text-align: center !important;
}

.dialog {
    font-size: 1000%;
    overflow-x: hidden !important;
    letter-spacing: -.2px;
}

.modal-body {
    padding: 2rem 3rem 3rem 3rem !important;
    border-radius: 10px !important;
}

input{
    font-size: 14px !important;
}

.userData div{
    font-size: 16px;
    margin-bottom: 10px;
}

.modal-content{
    
    border-radius: 10px !important;
}

.modal-comtent{
    max-height: 70vh;
    border-radius: 10px !important;
}

.modal-body{
    max-height: 70vh;
    overflow: auto;
    
}

 .btn{
    border-radius: 50px !important;
    /* padding: 10px 30px !important; */
    text-transform: none;
}




.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: -13px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
    }
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

