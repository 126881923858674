a{
  text-decoration: none;
}
.read-more{
  background-color: #fff !important;
  color: #02416c !important;
  padding: 15px 45px !important;
}


.btn-color{
  background: #244995 !important; 
  color: #fff !important;
  padding: 15px 45px !important;
}

.btn-color:hover{
  background: #cbdafb !important;
  color: #000000 !important;
}

.read-more:hover{
  background-color: #F2CA7Eb2 !important;
  color:#ffffff !important;
}

.btn{
  border: none !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.btn:hover {
	background: #cbdafb !important;
  color: #000000 !important;
}

.btn-primary{
  /* padding-left: 45px !important;
  padding-right: 45px !important; */
}

.reward-work .item{
  max-width: 30rem;
  word-wrap: normal;
}

.reward-work .item img{
  
}

.accordion .btn:hover{
  background-color: #ffffff !important;
  color: #2a3659 !important;
}

.reward-work .item{
  width: 15rem !important;
  margin-right: 0rem;
  margin-right: 1rem;
  
}

.reward-work img{
  background-color: #fff !important;
  max-width: 100% !important;
}

.reward-work p{
  max-width: 85% !important;
  text-align: left;
  display: none;
}

.reward-work h5{
  white-space: nowrap;
  text-align: center !important;
  color: #000000;
}

.marquee {
  align-items: flex-start !important;
}

.form-floating input, .form-floating select {
  border-radius: 10px;
}

.why-us-icon svg{
  font-size: 50px;
  margin-bottom: 10px;
}

.one-feature svg{
  font-size: 40px;
  margin-bottom: 20px;
}

.carrer-list{
  padding: 0 5%;
  padding-top: 100px;
}
.carrer-list a{
  text-decoration: none;
}
.carrer-item{
  color: #000000 !important;
  
}
.carrer-item h3:hover{
  color: #033752 !important;
  
}

.fileContainer {
  background: #fff;
  box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 5%);
  position: relative;
  border-radius: 10px;
  padding: 20px 0;
  display: block !important;
  align-items: left !important;
  justify-content: left !important;
  flex-direction: row;
  margin: 10px auto;
  transition: all 0.3s ease-in;
}

.home-img{
  /* height: 70vh !important; */
  
  
}

.para p{
  margin: 0 !important;
}

.para ul{
  padding-top: 15px;
}

.para li{
  padding-bottom: 15px;
}

.dot-label{
  height: 0px !important;
}

.dot-label .parent{
  padding-top: 40px !important;
  font-size: 18px;
  line-height: 40px;
}

.timeline ul:nth-child(2){
  display: none !important;
}

.info{
  padding-top: 20px;
}

.parent-active{
}

.parent-active .info{
  padding-top: 20px;
  border-radius: 10px;
  color:#fff !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #244995;
}

.parent-active .info h4{
  color:#fff !important;
}

.parent-active .text{
  border-radius: 10px;
  padding-top: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #244995;
  padding-bottom: 20px;
}

.parent-active .child{
  border-radius: 10px;
  color:#fff !important;
}

